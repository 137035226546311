export const checkForTokenOnLoad = () => {

    let accessToken = localStorage.getItem('accessToken');
    let accessTokenExpiry = localStorage.getItem('accessTokenExpiry');

    if(accessToken && accessTokenExpiry >= Date.now()) {
        return accessToken;
    }

    const accessTokenMatch = window.location.href.match(/access_token=([^&]*)/);
    const expiresInMatch = window.location.href.match(/expires_in=([^&]*)/);

    if (accessTokenMatch && expiresInMatch) {
        const accessToken = accessTokenMatch[1];
        const expiresIn = (Number(expiresInMatch[1])*1000);
        const expiresAt = Date.now() + expiresIn;

        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('accessTokenExpiry', expiresAt)
        
        window.history.pushState('Access Token', null, '/'); // Clear the token from the URL
        return accessToken;
    }
    return null; // Return null if no token is found
};

export const redirectToSpotifyLogin = (clientId, redirectUri) => {
    const accessUrl = `https://accounts.spotify.com/authorize?client_id=${clientId}&response_type=token&scope=playlist-modify-public&redirect_uri=${redirectUri}`;
    window.location = accessUrl; // Redirect to Spotify authorization page
};