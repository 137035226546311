import React from 'react';
import './track.css';
import { FaPlayCircle, FaPauseCircle, FaPlusCircle } from "react-icons/fa";

function Track({ name, artist, album, artwork, preview, isPlaying, onPlay, trackId, addToPlaylist, trackUri }) {
    return (
        <>
            <div id='trackContainer'>
                <div id='prevAudioContainer'>
                    <div onClick={onPlay}>
                        {preview !== 'No preview' ? (isPlaying ? <FaPauseCircle id='previewAudio'/> : <FaPlayCircle id='previewAudio'/>) :  <FaPlayCircle id='noPreviewAudio'/>}
                    </div>
                </div>
                <div id='artworkImgContainer'>
                    <img id='artworkImg' src={artwork} alt={`${name} artwork`} /> 
                </div>
                <div id='track-inf'>
                    <div id='an' className='mh'>
                        <p id='artist'>{artist} -&nbsp;</p>
                        <p id='name'>{name}</p>
                    </div>
                    <p id='album' className='mh'>{album}</p> 
                    <p id='artist' className='dh'>{artist}</p>
                    <p id='name' className='dh'>{name}</p>  
                </div>
                <FaPlusCircle id='addToPlaylist' onClick={addToPlaylist}/>   
            </div>
        </>
    );
}


export default Track;