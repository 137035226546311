import React, { useState, useEffect } from 'react';
import PlaylistTracks from './playlistTracks';

function Playlist({ playlistItems, PlaylistRemove, isPlaylistVisible, playlistSave, onPlaylistName }) {

    const [tracks, setTracks] = useState(playlistItems)
    const [playlistName, setPlaylistName] = useState('');

    useEffect(() => {
        setTracks(playlistItems);
    }, [playlistItems]);

    if (!isPlaylistVisible) {
        return null; // Don't render anything if the playlist isn't visible
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (playlistName.trim() === '') {
            alert('Please name playlist')
        } else {
            playlistSave(e);
        }
    };

    return (
        <div id='playlist-container' className={isPlaylistVisible ? 'visible' : ''}>
            <form onSubmit={handleFormSubmit}>
                <input
                    name='playlistName'
                    type='text'
                    id='playlistName'
                    value={playlistName}
                    placeholder='Set playlist name'
                    onChange={(e) => 
                                    {setPlaylistName(e.target.value);
                                    onPlaylistName(e.target.value)}}
                />
                {tracks.map((item, index) => (
                    <PlaylistTracks
                        key={index}
                        name={item.name}
                        artist={item.artist}
                        album={item.album}
                        artwork={item.artwork}
                        trackId={item.trackId}
                        trackUri={item.trackUri}
                        removeFromPlaylist={trackId => PlaylistRemove(trackId)}
                    />
                ))}
                <button type='submit'>Save playlist</button>
            </form>
        </div>
    );
}

export default Playlist;