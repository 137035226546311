import React, { useState } from 'react';
import searchFunction from './searchFunction';
import './searchBar.css';

function SearchBar({ onSearchResults, loginToken }) {
    
    const [searchInput, setSearchInput] = useState('');
    const [searchTerm, setSearchTerm] = useState('track');

    async function handleSubmit(e) {
        e.preventDefault()
        if (searchInput !== '') {
            try {
                const results = await searchFunction(searchInput,searchTerm,loginToken);
                onSearchResults(results);
        } catch (error) {
            alert('Search failed.')
        } 
    } else {
            alert('Search box empty.')
        }
    }

    return (
    <>
        <div className='container'>
            <form onSubmit={handleSubmit}>
                <div id='SearchBarContainer'>
                    <input name='search' type='text' id='search' value={searchInput} placeholder='Never gonna give you up' onChange={(e) => setSearchInput(e.target.value)}/> 
                    <select id='searchTypeSelector' onChange={(e) => setSearchTerm(e.target.value)}>
                        <option value='track'>Track</option>
                        <option value='track'>Artist</option>
                        <option value='track'>Album</option>
                    </select>
                </div>
                <button type='submit'>Search</button>
            </form>
        </div>
    </>
    )
}

export default SearchBar;