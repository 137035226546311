import React, { useState, useCallback } from 'react';
import SearchBar from './searchBar';
import SearchResults from './searchResults';
import Playlist from './playlist';
import NavBar from './navbar';
import savePlaylist from './savePlaylist';
import './App.css';

function App() {

  const [searchResults, setSearchResults] = useState([]);
  const [accessToken, setAccessToken] = useState('');
  const [trackPlaylist, setTrackPlaylist] = useState([]);
  const [isPlaylistVisible, setIsPlaylistVisible] = useState(false);
  const [playlistName, setPlaylistName] = useState('');

  const handleSearchResults = (results) => {
    setSearchResults(results); // Update state with search results
  };

  const handleLogin = useCallback((token) => {
    setAccessToken(token);
    localStorage.setItem('accessToken', token); // Store token in localStorage
  }, []);

  // Handle logout
  const handleLogout = () => {
    setAccessToken('');
    localStorage.removeItem('accessToken'); // Clear token from localStorage
  };

  const handlePlaylistAdd = (track) => {
    setTrackPlaylist([...trackPlaylist,{
                          trackId: track.trackId, 
                          name:track.name,
                          artist:track.artist,
                          artwork:track.artwork,
                          trackUri:track.trackUri
    }]);
    //setIsPlaylistVisible(true);
  };

  const handlePlaylistRemove = (trackId) => {
    const updatedPlaylist = trackPlaylist.filter(track => track.trackId !== trackId);
    setTrackPlaylist(updatedPlaylist);
    if (updatedPlaylist.length === 0) {
      setIsPlaylistVisible(false); // Hide playlist when it is empty
    }
  };

  const togglePlaylistVisibility = () => {
    setIsPlaylistVisible(!isPlaylistVisible);
  };

  const handlePlaylistName = (name) => {
    setPlaylistName(name);
  }

  const handlePlaylistSave = async (e) => {
    e.preventDefault();
    let trackArr = []
    trackPlaylist.forEach(track => {trackArr.push(track.trackUri)})
    const trackObj = {uris: trackArr}
    console.log(accessToken)
    console.log(playlistName)
    console.log(trackObj)
    savePlaylist(accessToken, playlistName, trackObj)
    };

  return (
    <div>
      <NavBar onLogin={handleLogin} onLogout={handleLogout} />
      <SearchBar onSearchResults={handleSearchResults} loginToken={accessToken} />
      {searchResults && searchResults.length > 0 && (
        <SearchResults results={searchResults} playlistAdd={handlePlaylistAdd} />
      )}

      {trackPlaylist.length > 0 && (
        <>
          <div id='togglePlaylistButtonContainer'>
            <p id='playlistSongCounter'>{trackPlaylist.length}</p>
            <button id='togglePlaylistButton' onClick={togglePlaylistVisibility}>
              {isPlaylistVisible ? 'Hide Playlist' : 'Show Playlist'}
            </button>
          </div>
        </>
      )}

      {isPlaylistVisible && (
        <Playlist playlistItems={trackPlaylist} PlaylistRemove={handlePlaylistRemove} isPlaylistVisible={isPlaylistVisible} playlistSave={handlePlaylistSave} onPlaylistName={handlePlaylistName} />
      )}
    </div>
  );
}

export default App;
