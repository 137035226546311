import React from 'react';
import Tracklist from './tracklist';

function SearchResults({ results, playlistAdd }) {
    return (
        <>
            <div>
                {results && results.length > 0 ? (
                    <>
                        <Tracklist initalSearchResult={results} AddToPlaylist={playlistAdd} />
                    </>
                ) : (
                    <h2>No Results Found</h2>
                )}
            </div>
        </>
    )
}

export default SearchResults;