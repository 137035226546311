import React, { useState, useEffect } from 'react';
import { checkForTokenOnLoad, redirectToSpotifyLogin } from './Client-Credentials';
import './navbar.css';

function Navbar({ onLogin, onLogout }) {
    const [accessToken, setAccessToken] = useState('');

    useEffect(() => {
        const token = checkForTokenOnLoad(); // Check for token on page load
        if (token) {
            setAccessToken(token);
            onLogin(token); // Pass the token up to the parent
        }
    }, [onLogin]);

    function handleLogin() {
        if (accessToken === '') {
            const clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
            const redirectUri = 'https://jammming.brennandigital.uk/';
            redirectToSpotifyLogin(clientId, redirectUri);
        }
    }

    function handleLogout() {
        setAccessToken(''); // Clear the local state
        onLogout(); // Trigger the parent logout function
    }

    return (
        <div id='container'>
            <div id='NavbarLeft'></div>
            <div id='NavbarCenter'><h1>Jammming</h1></div>
            <div id='NavbarRight'>
                {accessToken ? <> 
                                    <p id='loggedIn'>You are logged in</p>
                                    <button id='logoutButton' onClick={handleLogout}>Log Out</button>
                                </>
                                :
                                <button id='loginButton' onClick={handleLogin}>Log In</button>}
            </div>
        </div>
    );
}

export default Navbar;