import axios from 'axios';

const spotifyUrl = 'https://api.spotify.com/v1/search?q=';

const startSearch = async (term, type, accessToken) => {
    
    if (accessToken) {
        try {
            const response = await axios.get(spotifyUrl + encodeURI(term) + '&type=' + type + '&market=GB&limit=10', {
                headers: {
                    'Authorization': 'Bearer ' + accessToken
                },
            });
            const searchResults = response.data.tracks.items.map(track => ({
                name: track.name,
                artist: track.artists[0].name,
                album: track.album.name,
                artwork: track.album.images[0].url,
                preview: track.preview_url,
                trackId: track.id,
                trackUri: track.uri
            }));
            //console.log(searchResults);

            return searchResults;
            
        } catch (error) {
            console.error('Error search not complete:', error);
            throw error;
        }
    } else {
        return alert("Unable to authenticate. Please check you're logged in")
    }
}

export default startSearch;










/*


let OAuthToken = {
    accessToken: '',
    expirationTime: 0
};

const updateToken = async () => {
    const newToken = await getOAuthToken();
    OAuthToken.accessToken = newToken.accessToken;
    OAuthToken.expirationTime = newToken.expirationTime.getTime();
}

    if (OAuthToken.expirationTime <= new Date().getTime()) {
        await updateToken();
    } 


    */