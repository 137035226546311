import { React } from 'react';
import './track.css';
import { FaMinusCircle } from "react-icons/fa";

function PlaylistTracks({ name, artist, album, artwork, trackId, removeFromPlaylist }) {
    return (
        <>
            <div id='trackContainer' style={{background: `linear-gradient(to right, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.8) 40%), url(${artwork})`, backgroundSize: "cover", backgroundPosition: "center left"}}>
                <div id='track-inf'>
                    <div id='playlist-item-info' className='mh'>
                        <p id='artist'>{artist}</p>
                        <p id='name'>{name}</p>
                    </div> 
                </div>
                <FaMinusCircle id='removeFromPlaylist' onClick={() => removeFromPlaylist(trackId)}/>   
            </div>
        </>
    );
}


export default PlaylistTracks;