import { React, useState, useEffect } from 'react';
import Track from './track';

function Tracklist({ initalSearchResult, AddToPlaylist }) {
    const [tracks, setTracks] = useState(initalSearchResult)
    const [currentTrack, setCurrentTrack] = useState(null);
    const [audioInstance, setAudioInstance] = useState(null);

    useEffect(() => {
        setTracks(initalSearchResult);
        },[initalSearchResult]);

    const handlePlayPause = (previewUrl) => {
        if (audioInstance) {
            audioInstance.pause();
        }

        if (currentTrack === previewUrl) {
            setCurrentTrack(null); // Reset current track if the same track is clicked
        } else {
            const newAudio = new Audio(previewUrl);
            newAudio.volume = 0.05;
            newAudio.play().catch(error => {
                console.error('Error playing audio:', error);
            });

            setCurrentTrack(previewUrl);
            setAudioInstance(newAudio);
        }
    };

    return (
        <>
            <h2 style={{ margin: '10px auto', width: '75%' }}>Search Results</h2>
            {tracks.map((item, index) => (
                <Track 
                    key={index}
                    name={item.name}
                    artist={item.artist}
                    album={item.album}
                    artwork={item.artwork}
                    preview={item.preview !== null ? item.preview : 'No preview'}
                    isPlaying={currentTrack === item.preview}
                    onPlay={() => handlePlayPause(item.preview)}
                    trackId={item.trackId}
                    trackUri={item.trackUri}
                    addToPlaylist={() => AddToPlaylist(item)}
                />
            ))}
        </>
    );
}

export default Tracklist;