import axios from 'axios';

const getUserId = async(token) => {
    try {
        const response = await axios.get('https://api.spotify.com/v1/me', {
            headers: {
                'Authorization': 'Bearer ' + token,
            },
        });
        return response.data.id;
    } catch (error) {
        console.error('Error obtaining userID:', error);
        //alert('Error obtaining userID');
        throw error;
    }
}

const savePlaylist = async (accessToken, playlistName, playlistObj) => {

    let spotifyPlaylistId = null;

    const playlistNameData = {
        name: playlistName,
        description: "Created using Jammming"
    };

    try {
        const userId = await getUserId(accessToken);

        const spotifyPlaylistCreateUrl = `https://api.spotify.com/v1/users/${userId}/playlists`;

        const createPlaylistResponse = await axios.post(spotifyPlaylistCreateUrl, playlistNameData, {
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json'
            },
        });

        spotifyPlaylistId = createPlaylistResponse.data.id;

    } catch (error) {
        console.error('Error playlist creation failed:', error);
        //alert('Error - playlist creation failed')
        throw error;
    }

    const spotifyAddTracksToPlaylistUrl = `https://api.spotify.com/v1/playlists/${spotifyPlaylistId}/tracks`

    try {
        await axios.post(spotifyAddTracksToPlaylistUrl, playlistObj, {
            headers: {
                'Authorization': 'Bearer ' + accessToken,
                'Content-Type': 'application/json'
            },
        });
    } catch (error) {
        console.error('Error adding tracks to playlist:', error);
        //alert('Error adding tracks to playlist')
        throw error;
    }

}



//savePlaylist(sampleToken,'Test Playlist',sampleTrackData)

export default savePlaylist;